import * as React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import useTranslations from "./useTranslations";

const Footer = () => {
  const { getInTouch, allRightsReserved, address } = useTranslations();
  const menuItems = [
    { text: "Home", link: "#header" },
    { text: "About", link: "#about" },
    { text: "Approach", link: "#approach" },
    { text: "Our Team", link: "#team" },
  ];

  return (
    <footer
      className="footer has-background-black has-text-white-ter"
      id="contact"
    >
      <div className="content  has-text-white-ter">
        <div className="container">
          <div style={{ maxWidth: "100vw" }} className="columns">
            <div className="column is-3">
              <div className="is-size-2 has-text-weight-normal mb-5">
                {getInTouch}
              </div>
              <button
                className="button is-primary is-rounded"
                href="mailto:robert.charlier@yellowroad-nl.com"
              >
                robert.charlier@yellowroad-nl.com
              </button>
            </div>
            <div className="column is-6">
              <section className="has-text-centered">
                <div className="is-size-5 has-text-weight-bold mb-4">Menu</div>
                <div className="">
                  {menuItems.map((item, index) => (
                    <div className="mb-3" key={index}>
                      <AnchorLink offset="80" to="/" href={item.link}>
                        {item.text}
                      </AnchorLink>
                    </div>
                  ))}
                </div>
              </section>
            </div>
            <div className="column is-3">
              <div className="is-size-5 has-text-weight-bold mb-4">
                {address}
              </div>
              <div>
                <a
                  title="address"
                  target="_blank"
                  rel="noreferrer"
                  href="https://goo.gl/maps/TCYv6spSFxS3ektq6"
                >
                  Stationsplein 45, Unit A3.202 <br />
                  3013 AK
                  <br />
                  Rotterdam, Netherlands
                </a>
              </div>
            </div>
          </div>
          <hr style={{ height: 1 }} />
          <div className="content has-text-centered is-size-5 mt-4">
            © {new Date().getFullYear()} YellowRoad - {allRightsReserved}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
