import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import withI18next from "../i18n/withI18next";
import About from "../components/About";
import Approach from "../components/Approach";
import Team from "../components/Team";
import FullWidthMedia from "../components/FullWidthMedia";
import WhoWeAre from "../components/WhoWeAre";

// eslint-disable-next-line
export const IndexPageTemplate = ({ title, subtitle }) => {
  return (
    <div>
      <FullWidthMedia
        id="header"
        anchor={"#about"}
        video={
          "https://res.cloudinary.com/mol-meets/video/upload/q_auto:eco/f_auto,c_crop,g_south_east,w_0.9/v1678628239/YellowRoad/road.mov"
        }
        title={title}
        subtitle={subtitle}
        overlaycolor={"grey"}
        height={"90vh"}
      />
      <div className="section section--gradient">
        <div className="container">
          <div className="horiz-yellow-line-bottom-left"></div>
          <About id="about" />
          <WhoWeAre />
          <div className="horiz-yellow-line-top-right"></div>
          {/* <Icons /> */}
          <Approach />
          <div className="horiz-yellow-line-bottom-left"></div>
          <Team />
          <div className="horiz-yellow-line-top-left"></div>
        </div>
      </div>
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

const IndexPage = ({ data, location, pageContext: { locale } }) => {
  const { frontmatter } = data.markdownRemark;

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [location, i18n, locale]);

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter[locale].header.title}
        subtitle={frontmatter[locale].header.subtitle}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default withI18next()(IndexPage);

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { en: { templateKey: { eq: "index-page" } } }) {
      frontmatter {
        en {
          header {
            title
            subtitle
          }
        }
        nl {
          header {
            title
            subtitle
          }
        }
      }
    }
  }
`;
