import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import useFitText from "use-fit-text";
import AnchorLink from "react-anchor-link-smooth-scroll";

export default function FullWidthMedia(props) {
  const {
    height = 500,
    maxFont = 450,
    img,
    video,
    title,
    subtitle,
    imgPosition = "center",
    darken = false,
    anchor,
    overlaycolor,
  } = props;

  const { fontSize, ref } = useFitText({ maxFontSize: maxFont });

  return (
    <React.Fragment>
      <div
        className="margin-top-0"
        style={{
          display: "grid",
          alignItems: "center",
          position: "relative",
        }}
      >
        {video ? (
          <>
            <video
              autoPlay
              muted
              loop
              id="myVideo"
              style={{
                gridArea: "1/1",
                // You can set a maximum height for the image, if you wish.
                maxHeight: height,
                height: height,
                width: "100%",
                filter: darken ? "brightness(50%)" : false,
                objectFit: "cover",
              }}
            >
              <source src={video} type="video/mp4" />
            </video>
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 0,
                background: overlaycolor,
                opacity: 1,
                mixBlendMode: "overlay",
              }}
            ></div>
          </>
        ) : img ? (
          img?.url ? (
            <img
              src={img}
              objectFit={"cover"}
              objectPosition={imgPosition}
              style={{
                gridArea: "1/1",
                // You can set a maximum height for the image, if you wish.
                height: height,
                width: "100%",
                filter: darken ? "brightness(50%)" : false,
              }}
              // You can optionally force an aspect ratio for the generated image
              aspectratio={3 / 1}
              // This is a presentational image, so the alt should be an empty string
              alt=""
              formats={["auto", "webp", "avif"]}
            />
          ) : (
            <>
              <GatsbyImage
                image={img}
                objectFit={"contains"}
                objectPosition={imgPosition}
                style={{
                  gridArea: "1/1",
                  // You can set a maximum height for the image, if you wish.
                  maxHeight: height,
                  height: height,
                  filter: darken ? "brightness(50%)" : false,
                }}
                layout="fullWidth"
                // You can optionally force an aspect ratio for the generated image
                aspectratio={3 / 1}
                // This is a presentational image, so the alt should be an empty string
                alt=""
                formats={["auto", "webp", "avif"]}
              />

              {/* <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 0,
                  background: overlaycolor,
                  opacity: 1,
                  mixBlendMode: "overlay",
                }}
              ></div> */}
            </>
          )
        ) : (
          <div
            style={{
              width: "100%",
              minHeight: height,
              background: "black",
              gridArea: "1/1",
            }}
          ></div>
        )}
        {anchor && (
          <AnchorLink href={anchor} className="scroll-button round-button">
            <div className="round">
              <div className="arrow"></div>
            </div>
          </AnchorLink>
        )}
        {(title || subtitle) && (
          <div
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: "1/1",
              position: "relative",
              // This centers the other elements inside the hero component
              placeItems: "center",
              display: "grid",
            }}
          >
            {/* Any content here will be centered in the component */}
            {title && (
              <h1
                className="centered-media-header"
                ref={ref}
                style={{
                  fontSize,
                  fontWeight: 400,
                  width: "60%",
                  textAlign: "center",
                  textTransform: "capitalize",
                  color: "white",
                  lineHeight: "normal",
                }}
              >
                {title}
              </h1>
            )}
            {subtitle && (
              <h1
                className="has-text-centered"
                style={{
                  width: "80%",
                  fontSize,
                  fontWeight: 400,
                  color: "white",
                  lineHeight: "normal",
                }}
              >
                {subtitle}
              </h1>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

FullWidthMedia.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subtitle: PropTypes.string,
  anchor: PropTypes.string,
  overlaycolor: PropTypes.string,
  maxFont: PropTypes.number,
};
