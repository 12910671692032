import React from "react";
import { graphql, StaticQuery } from "gatsby";
import localeContext from "../localeContext";
import { FlipModal } from "./YellowModal";
import Modal from "react-modal";

Modal.setAppElement("body");

const ContentBox = ({ content }) => {
  return (
    <FlipModal
      title={content.title}
      body={content.body}
      image={content.image}
    />
  );
};

export const ApproachTemplate = (props) => {
  const approach = props.data.approach;

  return (
    <section className="section content" id="approach">
      <div className="columns is-multiline">
        <div className="is-parent is-offset-2 column is-8 has-text-centered">
          <h2>{approach.title}</h2>
          <h3>{approach.subtitle}</h3>
        </div>

        <div className="columns is-full is-multiline" style={{ width: "100%" }}>
          {approach.content.map((item, index) => (
            <div className="column is-6" key={index}>
              <ContentBox content={item} />
            </div>
          ))}
          {/* <div className="tile is-ancestor">
            <div className="tile is-6 is-vertical is-parent">
              <ContentBox content={approach.content[0]} />
              <ContentBox content={approach.content[1]} />
            </div>
            <div className="tile is-vertical is-parent">
              <ContentBox content={approach.content[2]} />
              <ContentBox content={approach.content[3]} />
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default function Approach() {
  const { locale } = React.useContext(localeContext);

  return (
    <StaticQuery
      query={graphql`
        query Approach {
          markdownRemark(
            frontmatter: { en: { templateKey: { eq: "index-page" } } }
          ) {
            frontmatter {
              en {
                approach {
                  title
                  subtitle
                  content {
                    title
                    body
                    image {
                      childImageSharp {
                        gatsbyImageData(quality: 100)
                      }
                    }
                  }
                }
              }
              nl {
                approach {
                  title
                  subtitle
                  content {
                    title
                    body
                    image {
                      childImageSharp {
                        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <ApproachTemplate data={data.markdownRemark.frontmatter[locale]} />
      )}
    />
  );
}
