import React from "react";
import { graphql, StaticQuery } from "gatsby";
import localeContext from "../localeContext";
import PreviewCompatibleImage from "./PreviewCompatibleImage";

export const AboutTemplate = (props) => {
  const about = props.data.about;

  return (
    <section className="section content" id="about">
      <div className="columns is-multiline is-vcentered">
        <div className="is-parent column is-5">
          <h2>{about.title}</h2>
          <h3>{about.subtitle}</h3>
          {about.content.map((item, index) => (
            <div className="pargraph" key={index}>
              <h4>{item.title}</h4>
              <p>{item.body}</p>
            </div>
          ))}
        </div>
        <div className="is-parent column is-offset-1 is-6">
          <PreviewCompatibleImage imageInfo={about.image} />
        </div>
      </div>
    </section>
  );
};

export default function About() {
  const { locale } = React.useContext(localeContext);

  return (
    <StaticQuery
      query={graphql`
        query About {
          markdownRemark(
            frontmatter: { en: { templateKey: { eq: "index-page" } } }
          ) {
            frontmatter {
              en {
                about {
                  title
                  subtitle
                  image {
                    childImageSharp {
                      gatsbyImageData(quality: 100, aspectRatio: 1)
                    }
                  }
                  content {
                    title
                    body
                  }
                }
              }
              nl {
                about {
                  title
                  subtitle
                  image {
                    childImageSharp {
                      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    }
                  }
                  content {
                    title
                    body
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <AboutTemplate data={data.markdownRemark.frontmatter[locale]} />
      )}
    />
  );
}
