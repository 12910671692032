import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import LocalizedLink from "./localizedLink";
import logo from "../img/Yellowroad1.svg";
import logoWit from "../img/Yellowroad1wit.svg";

const Navbar = () => {
  const [isActive, setIsActive] = useState(false);

  const [scrollState, setScrollState] = useState("top");

  useEffect(() => {
    let listener = null;
    listener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 120) {
        if (scrollState !== "amir") setScrollState("amir");
      } else {
        if (scrollState !== "top") setScrollState("top");
      }
    });
    return () => {
      document.removeEventListener("scroll", listener);
    };
  }, [scrollState]);

  const menuItems = [
    { text: "About", link: "#about" },
    { text: "Approach", link: "#approach" },
    { text: "Our Team", link: "#team" },
    { text: "Contact", link: "#contact" },
  ];

  return (
    <nav
      className={`navbar is-transparent ${
        isActive ? "" : "is-fixed-top"
      } ${scrollState}`}
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="navbar-brand">
        <LocalizedLink to="/" className="navbar-item">
          <img
            src={scrollState == "amir" ? logo : logoWit}
            alt="Yellowroad"
            style={{ width: 200 }}
          />
        </LocalizedLink>
      </div>
      <div className={`container ${isActive ? "is-active" : ""}`}>
        <div
          id="navMenu"
          className={`has-text-centered navbar-menu ${
            isActive ? "is-active" : ""
          }`}
        >
          <div className="navbar has-text-centered">
            {/* TODO: inline override of padding is a result of refactoring
                to a ul for accessibilty purposes, would like to see a css
                re-write that makes this unneccesary.
             */}
            <LocalizedLink to="/" className="navbar-item">
              Home
            </LocalizedLink>
            {menuItems.map((item, index) => (
              <AnchorLink
                offset="80"
                href={item.link}
                className="navbar-item"
                key={index}
                onClick={() => setIsActive(false)}
              >
                {item.text}
              </AnchorLink>
            ))}
            {/* <div className="navbar-item">
              <Link to="/" hrefLang="en">
                EN
              </Link>
              {` `}/{` `}
              <Link to="/nl" hrefLang="nl">
                NL
              </Link>
            </div> */}
          </div>
        </div>
      </div>
      <div className={`navbar-brand ${isActive && "is-active"}`}>
        {/* Hamburger menu */}
        <button
          className={`navbar-burger burger ${isActive && "is-active"}`}
          aria-expanded={isActive}
          data-target="navMenu"
          role="menuitem"
          onClick={() => setIsActive(!isActive)}
        >
          <span />
          <span />
          <span />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
