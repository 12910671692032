import React from "react";
import { graphql, StaticQuery } from "gatsby";
import localeContext from "../localeContext";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import ReactMarkdown from "react-markdown";

export const WhoWeAreTemplate = (props) => {
  const who = props.data.who;

  return (
    <section className="section content" id="who-we-are">
      <div className="columns is-multiline is-vcentered">
        <div className="is-parent column is-6">
          <PreviewCompatibleImage imageInfo={who.image} />
        </div>
        <div className="is-parent column is-offset-1 is-5">
          <h2>{who.title}</h2>
          <h3>{who.subtitle}</h3>
          <ReactMarkdown>{who.content}</ReactMarkdown>
        </div>
      </div>
    </section>
  );
};

export default function WhoWeAre() {
  const { locale } = React.useContext(localeContext);

  return (
    <StaticQuery
      query={graphql`
        query WhoWeAre {
          markdownRemark(
            frontmatter: { en: { templateKey: { eq: "index-page" } } }
          ) {
            frontmatter {
              en {
                who {
                  title
                  subtitle
                  image {
                    childImageSharp {
                      gatsbyImageData(quality: 100, aspectRatio: 1)
                    }
                  }
                  content
                }
              }
              nl {
                who {
                  title
                  subtitle
                  image {
                    childImageSharp {
                      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                    }
                  }
                  content
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <WhoWeAreTemplate data={data.markdownRemark.frontmatter[locale]} />
      )}
    />
  );
}
