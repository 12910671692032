import React, { useRef } from "react";
import { graphql, StaticQuery } from "gatsby";
import localeContext from "../localeContext";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { CiMail, CiLinkedin, CiPhone } from "react-icons/ci";
import { useSyncRefHeight } from "./Resizer";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

function shuffle(array, seed) {
  // <-- ADDED ARGUMENT
  var m = array.length,
    t,
    i;

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(random(seed) * m--); // <-- MODIFIED LINE

    // And swap it with the current element.
    t = array[m];
    array[m] = array[i];
    array[i] = t;
    ++seed; // <-- ADDED LINE
  }

  return array;
}

function random(seed) {
  var x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

const ItemCard = ({ employee }) => {
  // create ref to the parent container, to only target its children instead of running query on the entire document
  const itemsRef = useRef(null);
  const imageRef = useRef(null);
  // align elements with class items
  // deps is an empty array, so it will only be aligned when the component is mounted.
  // You can add your dependencies, or remove it to make sure the hook runs at every render
  useSyncRefHeight(
    [["resize-item", itemsRef]],
    // trigger hook when items of footerItems changes, since it may change height
    [employee]
  );
  return (
    <div className="card">
      <div className="card-image">
        <figure className="image">
          <PreviewCompatibleImage imageInfo={employee.image} noBorder={true} />
        </figure>
      </div>

      <div className="card-content">
        <div className="content resize-item" ref={itemsRef}>
          <p className="title">{employee.title}</p>
          <p className="subtitle">{employee.body}</p>
        </div>
        <div className="button-grid">
          {employee.phone && (
            <a
              href={"tel:" + employee.phone}
              className="button is-primary is-rounded"
            >
              <span className="icon is-medium">
                <CiPhone />
              </span>
            </a>
          )}
          {employee.email && (
            <a
              href={"mailto:" + employee.email}
              className="button is-dark is-rounded"
            >
              <span className="icon is-medium">
                <CiMail />
              </span>
            </a>
          )}
          {employee.linkedin && (
            <a
              href={employee.linkedin}
              className="button is-primary is-rounded"
            >
              <span className="icon is-medium">
                <CiLinkedin />
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export const TeamTemplate = (props) => {
  const team = props.data.team;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="section content" id="team">
      <div className="columns is-vcentered is-multiline">
        <div className="column is-3">
          <h2>{team.title}</h2>
          <h3>{team.subtitle}</h3>
        </div>
        <div className="column is-9">
          <Carousel
            responsive={responsive}
            removeArrowOnDeviceType={["tablet", "mobile"]}
          >
            {props.employees.map((employee, index) => (
              <div key={index}>
                <ItemCard employee={employee} key={index} />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </section>
  );
};

export default function Team() {
  const { locale } = React.useContext(localeContext);

  return (
    <StaticQuery
      query={graphql`
        query Team {
          markdownRemark(
            frontmatter: { en: { templateKey: { eq: "index-page" } } }
          ) {
            frontmatter {
              en {
                team {
                  title
                  subtitle
                  employees {
                    title
                    body
                    email
                    phone
                    linkedin
                    image {
                      childImageSharp {
                        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
              nl {
                team {
                  title
                  subtitle
                  employees {
                    title
                    body
                    email
                    phone
                    linkedin
                    image {
                      childImageSharp {
                        gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <TeamTemplate
          data={data.markdownRemark.frontmatter[locale]}
          employees={shuffle(
            data.markdownRemark.frontmatter[locale].team.employees,
            Math.random() * 100
          )}
        />
      )}
    />
  );
}
