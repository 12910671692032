import React from "react";
import Modal from "react-modal";
import { BgImage } from "gbimage-bridge";
import "./modal.css";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import PreviewCompatibleImage from "./PreviewCompatibleImage";
import { getImage } from "gatsby-plugin-image";

export function FlipModal({ title, body, image }) {
  return (
    <div className="flip-card">
      <PreviewCompatibleImage className="flip-img" imageInfo={image} />
      <h4 className="title">{title}</h4>
      <div className="info">
        <h1>{title}</h1>
        <p>
          <ReactMarkdown>{body}</ReactMarkdown>
        </p>
      </div>
    </div>
  );
}

export default function YellowModal({ title, body, image }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const pluginImage = getImage(image);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <BgImage
        onClick={openModal}
        className="tile is-child box background pointer approach"
        image={pluginImage}
      >
        <h4>{title}</h4>
      </BgImage>
      <Modal
        closeTimeoutMS={1000}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="yellow-modal"
        overlayClassName="yellow-modal-overlay"
      >
        <span
          class="close"
          onClick={closeModal}
          aria-label="close"
          onKeyDown={closeModal}
        ></span>
        <section className="section content">
          <h4>{title}</h4>
          <ReactMarkdown>{body}</ReactMarkdown>
        </section>
      </Modal>
    </>
  );
}
